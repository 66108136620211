import { render, staticRenderFns } from "./info.vue?vue&type=template&id=6060d945&scoped=true&lang=pug&"
import script from "./info.vue?vue&type=script&setup=true&lang=js&"
export * from "./info.vue?vue&type=script&setup=true&lang=js&"
import style0 from "./info.vue?vue&type=style&index=0&id=6060d945&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6060d945",
  null
  
)

export default component.exports